import { AboutPageContextProvider } from './context/AboutPageContext';
import { Routes, Route } from 'react-router-dom';
import GuideQuickIntro from './pages/guide-quick-intro/GuideQuickIntro';
import DatabaseStructure from './pages/database-structure/DatabaseStructure';
import DashboardsDetails from './pages/dashboards-details/DashboardsDetails';
import RaspberryPiSetup from './pages/raspberrypi-setup/RaspberryPiSetup';
import StarterProject from './pages/starter-project/StarterProject';
import DashjsInDetails from './pages/dashjs-indetails/DashjsInDetails';
import DemoProjects from './pages-demo-projects/DemoProjects';
import RunProjectAsService from './pages/run-project-as-service/RunProjectAsService';

function App() {

  return (
   <AboutPageContextProvider>
    <Routes>
      <Route path='/*' element={<GuideQuickIntro />}> </Route>
      <Route path='/databasestructure' element={<DatabaseStructure />}> </Route>
      <Route path='/dashboardsdetails' element={<DashboardsDetails />}> </Route>
      <Route path='/raspberrypisetup' element={<RaspberryPiSetup />}> </Route>
      <Route path='/starterproject' element={<StarterProject />}> </Route>
      <Route path='/dashjsindetails' element={<DashjsInDetails />}> </Route>
      <Route path='/runaprojectasaservice' element={<RunProjectAsService />}> </Route>
      <Route path='/demoprojects' element={<DemoProjects />}> </Route>
    </Routes>   
    </AboutPageContextProvider>
  );
}

export default App;
